import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  input,
  signal,
  type OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs';

import { generateGenericAvatar } from '@clover/core/helpers/generateGenericAvatar';
import { UserActivityService } from '@clover/core/services/user-activity.service';
import { UserService } from '@clover/core/services/user.service';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

@UntilDestroy()
@Component({
  selector: 'cc-user-avatar',
  standalone: true,
  imports: [TooltipDirective],
  templateUrl: './user-logo.component.html',
  styleUrl: './user-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnInit {
  src = input.required<string>();
  name = input.required<string, string | string[]>({
    transform: (value: string | string[]) => (Array.isArray(value) ? value.join(' ') : value || ''),
  });
  size = input.required<number>();

  id = input<number | string | undefined>(undefined);

  showNameTooltip = input<boolean>(false);

  avatarSrc = computed(() => {
    if (this.src()) return this.src();

    const [firstName = '–', lastName] = this.name().split(' ');
    return generateGenericAvatar(firstName, lastName, this.size() * 2);
  });

  online = signal<boolean>(false);

  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly activityService = inject(UserActivityService);
  private readonly userService = inject(UserService);

  @HostBinding('style.height.px')
  get height(): number {
    return this.size();
  }

  @HostBinding('style.width.px')
  get width(): number {
    return this.size();
  }

  @HostBinding('class.cc-online')
  get isOnline(): boolean {
    return this.online();
  }

  ngOnInit(): void {
    this.scheduleOnlineCheck();
  }

  private scheduleOnlineCheck(): void {
    this.activityService.statusUpdated$
      .pipe(startWith(null), untilDestroyed(this))
      .subscribe(() => this.updateOnlineStatus());
  }

  private updateOnlineStatus(): void {
    const id = this.id();

    if (!id) return this.online.set(false);
    if (id === 'system') return this.online.set(true);
    if (id === this.userService.userProfile.id) return this.online.set(true);

    const numericId = Number(id);
    if (Number.isNaN(numericId)) return this.online.set(false);

    this.online.set(this.activityService.getActiveStatus(numericId));
  }
}
